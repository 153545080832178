import DocxGenerator from "@/core/services/docxgenerator.service";
import dayjs from "dayjs";
import draggable from "vuedraggable";
import { DATE_FORMAT, DATETIME_FORMAT } from "@/core/constants/time";
import { OPERATOR_ENUM } from "@/core/constants";
import { PAGE_REQUEST_DATA } from "@/store/common/page.module";
import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import {
  childRefEnum,
  parentHeader,
  sections
} from "@/view/content/ServiceManagementReport/constants";
import { GET_ACCOUNTS } from "@/core/services/graphql/queries/dynamics365/account";
import NotificationService from "@/core/services/notification.service";

export default {
  apollo: {
    accounts: {
      query: () => GET_ACCOUNTS,
      variables() {
        return {
          filters: {
            active: { value: this.activeCustomers },
            sen_provider: { value: this.selectedProvider }
          }
        };
      },
      update(data) {
        return data.accounts;
      },
      skip() {
        return this.skipQuery;
      },
      watchLoading(isLoading) {
        this.$store.dispatch(PAGE_REQUEST_DATA, isLoading);
      },
      error(error) {
        NotificationService.error(error);
      }
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      activeCustomers: true,
      allOpen: false,
      allSelected: false,
      dates: [
        dayjs().subtract(1, "months").startOf("month").format(DATE_FORMAT.DAY),
        dayjs().subtract(1, "months").endOf("month").format(DATE_FORMAT.DAY)
      ],
      exportData: [],
      items: sections,
      providerOptions: [
        { display: "Sentia BE", value: "Sentia BE" },
        { display: "Sentia DK", value: "Sentia DK" },
        { display: "Sentia NL", value: "Sentia NL" }
      ],
      selectedCustomer: {},
      selectedProvider: "",
      showRangePicker: false,
      selectedItems: []
    };
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Group"
      },
      {
        title: "Service Management Reports"
      }
    ]);
  },
  methods: {
    setData(event) {
      this.exportData.push({ [event.ref]: event.data });
      if (this.exportData.length === Object.keys(childRefEnum).length) {
        this.$store.dispatch(PAGE_REQUEST_DATA, false);
        let indexes = [];
        for (let i = this.items.length - 1; i >= 0; i--) {
          if (this.items[i].selected === true) indexes.unshift(i);
        }
        this.selectedItems = indexes;
      }
    },
    reloadAccounts() {
      if (this.selectedProvider) {
        this.$apollo.queries.accounts.skip = false;
        this.$apollo.queries.accounts.refetch();
      }
    },
    async fetchData() {
      this.$store.dispatch(PAGE_REQUEST_DATA, true);
      let accountNumber = this.selectedCustomer.accountnumber;
      let dateFrom = this.dates[0];
      let dateTo = this.dates[1];
      let dateTimeTo = dayjs(dateTo, DATE_FORMAT.DAY)
        .utc()
        .add(23, "hours")
        .add(59, "minutes")
        .add(59, "seconds")
        .format(DATETIME_FORMAT.ISO);
      let dateTimeFrom = dayjs(dateFrom, DATE_FORMAT.DAY)
        .utc()
        .format(DATETIME_FORMAT.ISO);

      let openedAtFilter = {
        value: [dateTimeFrom, dateTimeTo],
        operator: OPERATOR_ENUM.BETWEEN
      };
      let accountNumberFilter = { value: [accountNumber] };
      Object.values(childRefEnum).forEach(refName => {
        this.$refs[refName][0].parseAndRetrieveData({
          openedAtFilter,
          accountNumberFilter,
          accountNumber: accountNumber
        });
      });
    },
    parseData() {
      /*
      OUTPUT:
      [
        {
          title: Title 1,
          children:
          [
            {
              data: [],
              type: "type",
              subtitle: ""
            }
          ]
        }
      ]
      */
      const exportItemsSorted = [];
      this.items.forEach(item => {
        let exportItem = {};

        exportItem["header"] = item.header;
        exportItem["children"] = [];
        if (item.selected) {
          item.children.forEach(child => {
            let data = (this.exportData.find(
              data => Object.keys(data)[0] === child.ref
            ) || {})[child.ref];
            let childItem = {
              data: data,
              type: child.type,
              country: this.selectedProvider
            };
            if (
              item.header === parentHeader.CLOSED_CHANGES ||
              item.header === parentHeader.CREATED_CHANGES ||
              item.header === parentHeader.OPEN_CHANGES ||
              item.header === parentHeader.OVERVIEW_CHANGES
            ) {
              childItem["level_name"] = "Type";
            }
            if (
              item.header === parentHeader.CLOSED_INCIDENTS ||
              item.header === parentHeader.CREATED_INCIDENTS ||
              item.header === parentHeader.OPEN_INCIDENTS ||
              item.header === parentHeader.OVERVIEW_INCIDENTS
            ) {
              childItem["level_name"] = "Priority";
            }
            if (
              item.header === parentHeader.CLOSED_SECURITY_INCIDENTS ||
              item.header === parentHeader.CREATED_SECURITY_INCIDENTS ||
              item.header === parentHeader.OPEN_SECURITY_INCIDENTS ||
              item.header === parentHeader.OVERVIEW_SECURITY_INCIDENTS
            ) {
              childItem["level_name"] = "Severity";
            }
            if (
              item.header === parentHeader.CLOSED_PROBLEMS ||
              item.header === parentHeader.CREATED_PROBLEMS ||
              item.header === parentHeader.OPEN_PROBLEMS
            ) {
              childItem["level_name"] = "Priority";
            }
            if (
              item.header === parentHeader.CLOSED_SERVICE_REQUESTS ||
              item.header === parentHeader.CREATED_SERVICE_REQUESTS ||
              item.header === parentHeader.OPEN_SERVICE_REQUESTS
            ) {
              childItem["level_name"] = "Priority";
            }
            if ("subTitle" in child) {
              childItem["subTitle"] = child.subTitle;
            }
            exportItem.children.push(childItem);
          });
          exportItemsSorted.push(exportItem);
        }
      });
      return exportItemsSorted;
    },
    exportFile() {
      const exportItemsSorted = this.parseData();

      const generate = {};
      generate.title = `Service_Level_Report_${this.selectedCustomer.accountnumber}`;
      generate.templateUrl = "/templates/sg_slr.docx";
      generate.data = {
        items: exportItemsSorted,
        information: {
          date_from: this.dates[0],
          date_to: this.dates[1],
          company_name: this.selectedCustomer.name,
          customer_number: this.selectedCustomer.accountnumber,
          creation_date: dayjs().format(DATE_FORMAT.DAY)
        }
      };
      DocxGenerator.generate(generate);
    },
    selectCheckboxes() {
      this.allSelected = !this.allSelected;
      this.items.forEach(item => {
        item.selected = this.allSelected;
      });
    },
    openViews() {
      this.allOpen = !this.allOpen;
      if (this.allOpen) {
        this.selectedItems = Array.from(
          { length: this.items.length },
          (v, i) => i
        );
      } else {
        this.selectedItems = [];
      }
    }
  }
};
