import Component from "vue-class-component";
import Vue from "vue";
import { OPERATOR_ENUM, STATE_FILTER_ENUM } from "@/core/constants";
import { TimeService } from "@/core/services/time.service";

const dayjs = require("dayjs");
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const { DATETIME_FORMAT } = require("@/core/constants/time");

export const ParseOptions = {
  SLA: "sla"
};

@Component
class ReportBase extends Vue {
  openIncidentFilter = {
    value: [
      STATE_FILTER_ENUM.NEW,
      STATE_FILTER_ENUM.IN_PROGRESS,
      STATE_FILTER_ENUM.ON_HOLD,
      STATE_FILTER_ENUM.RESOLVED
    ],
    operator: OPERATOR_ENUM.IN
  };
  openProblemFilter = {
    value: [
      STATE_FILTER_ENUM.NEW,
      STATE_FILTER_ENUM.ASSESS,
      STATE_FILTER_ENUM.ROOT_CAUSE_ANALYSIS,
      STATE_FILTER_ENUM.FIX_IN_PROGRESS
    ],
    operator: OPERATOR_ENUM.IN
  };
  openChangeFilter = {
    value: [
      STATE_FILTER_ENUM.NEW,
      STATE_FILTER_ENUM.ASSESS,
      STATE_FILTER_ENUM.AUTHORIZE,
      STATE_FILTER_ENUM.SCHEDULED,
      STATE_FILTER_ENUM.IMPLEMENT,
      STATE_FILTER_ENUM.REVIEW
    ],
    operator: OPERATOR_ENUM.IN
  };

  closedIncidentFilter = {
    value: [STATE_FILTER_ENUM.CLOSED, STATE_FILTER_ENUM.CANCELED],
    operator: OPERATOR_ENUM.IN
  };
  closedProblemFilter = {
    value: [STATE_FILTER_ENUM.CLOSED, STATE_FILTER_ENUM.RESOLVED],
    operator: OPERATOR_ENUM.IN
  };
  closedChangesFilter = {
    value: [STATE_FILTER_ENUM.CLOSED, STATE_FILTER_ENUM.CANCELED],
    operator: OPERATOR_ENUM.IN
  };

  async parseAndRetrieveData() {
    throw Error("Not implemented");
  }

  async queryData({ query, variables, path, client }) {
    client = client ? client : "connectClient";
    let response = await this.$apollo
      .query({
        query: query,
        variables: variables,
        fetchPolicy: "no-cache",
        client: client
      })
      .then(result => {
        return result.data;
      });
    try {
      return path.split(".").reduce((o, k) => o && o[k], response);
    } catch (error) {
      throw Error("Failed to find JSON Path Value");
    }
  }

  parseRating({ data }) {
    const ratingList = [];
    data.result.forEach(rating => {
      ratingList.push(Number(rating.actualValue));
    });
    let averageRating;
    if (ratingList.length) {
      averageRating = (
        ratingList.reduce((a, b) => a + b, 0) / ratingList.length
      ).toFixed(1);
    } else {
      averageRating = "n/a";
    }
    return {
      averageRating: averageRating,
      totalCount: data.totalCount
    };
  }

  parseDetail({ data, parseOptions }) {
    data = data ? data : [];
    let parsedData;
    if (parseOptions.includes(ParseOptions.SLA))
      data = this.setResponseAndResolveTime({ overviewData: data });
    data.forEach(item => {
      item.sys_created_on = dayjs
        .utc(item.sys_created_on, DATETIME_FORMAT.SECOND)
        .local()
        .format(DATETIME_FORMAT.SECOND);
    });
    parsedData = data;
    return parsedData;
  }

  setLevel({ data, levelName }) {
    data = data ? data : [];
    data.forEach(item => {
      item["level"] = item[levelName];
    });
    return data;
  }

  parseOverview({ data }) {
    data.forEach(level => {
      let convertedData = level;
      // Level
      convertedData.level = level.level.replace("level_", "");

      // Average times
      let averageResponseTimeString = TimeService.convertSecondsToString(
        level.averageResponseTime
      );
      let slaResponseTimeString = TimeService.convertSecondsToString(
        level.slaResponseTime
      );
      if (averageResponseTimeString && slaResponseTimeString)
        convertedData.averageResponseTime = `${averageResponseTimeString} / ${slaResponseTimeString}`;
      else if (!averageResponseTimeString && !slaResponseTimeString)
        convertedData.averageResponseTime = "n/a";
      else
        convertedData.averageResponseTime = `${
          averageResponseTimeString ? averageResponseTimeString : "n/a"
        } / ${slaResponseTimeString ? slaResponseTimeString : "n/a"}`;

      let averageResolveTimeString = TimeService.convertSecondsToString(
        level.averageResolveTime
      );
      let slaResolveTimeString = TimeService.convertSecondsToString(
        level.slaResolveTime
      );
      if (averageResolveTimeString && slaResolveTimeString)
        convertedData.averageResolveTime = `${averageResolveTimeString} / ${slaResolveTimeString}`;
      else if (!averageResolveTimeString && !slaResolveTimeString)
        convertedData.averageResolveTime = "n/a";
      else
        convertedData.averageResolveTime = `${
          averageResolveTimeString ? averageResolveTimeString : "n/a"
        } / ${slaResolveTimeString ? slaResolveTimeString : "n/a"}`;

      // Times ok
      convertedData.responseTimeOk =
        typeof level.responseTimeOk == "number"
          ? `${Math.floor(Number(level.responseTimeOk))} %`
          : "n/a";
      convertedData.resolveTimeOk =
        typeof level.resolveTimeOk == "number"
          ? `${Math.floor(Number(level.resolveTimeOk))} %`
          : "n/a";
    });
    return data;
  }

  parseDetailByLevel({ data, levels, levelName, parseOptions }) {
    let parsedData = [];
    if (parseOptions.includes(ParseOptions.SLA))
      data = this.setResponseAndResolveTime({ overviewData: data });
    try {
      data.forEach(itil => {
        itil.sys_created_on = dayjs
          .utc(itil.sys_created_on, DATETIME_FORMAT.SECOND)
          .local()
          .format(DATETIME_FORMAT.SECOND);
        levels[itil[levelName]].push(itil);
      });
      Object.keys(levels).forEach(level => {
        parsedData.push({ data: levels[level], level: level });
      });
    } catch (error) {
      throw Error(error);
    }
    return parsedData;
  }

  setResponseAndResolveTime({ overviewData }) {
    overviewData.forEach(item => {
      item.resolveTime = this.calculateTimes(item.resolveTime);
      item.responseTime = this.calculateTimes(item.responseTime);
      item.rating = item.rating ? item.rating : "";
    });
    return overviewData;
  }

  calculateTimes(item) {
    let businessDuration = "n/a";
    if (item == null) return businessDuration;
    try {
      let datetimeObject = dayjs.utc(item, DATETIME_FORMAT.SECOND).toDate();
      let datetimeSeconds = datetimeObject / 1000;
      businessDuration = TimeService.convertSecondsToString(datetimeSeconds);
    } catch (error) {
      businessDuration = "n/a";
    }
    return businessDuration;
  }

  customSort(items, sortBy, sortDesc) {
    if (sortBy.length === 0 && sortDesc.length === 0) {
      this.data = this.defaultData;
    }
    for (let index = 0; index < sortBy.length; index++) {
      const sortHeader = sortBy[index];
      const isDescending = sortDesc[index];

      if (sortHeader === "priority") {
        this.data.sort((a, b) => {
          if (isDescending) {
            return b.priority.index - a.priority.index;
          } else {
            return a.priority.index - b.priority.index;
          }
        });
      } else {
        this.data.sort((a, b) => {
          if (!isDescending) {
            return a[sortHeader] < b[sortHeader] ? -1 : 1;
          } else {
            return b[sortHeader] < a[sortHeader] ? -1 : 1;
          }
        });
      }
    }
    return this.data;
  }

  dataLoaded(ref, parentHeader) {
    this.$emit("dataLoaded", {
      ref: ref,
      parentHeader: parentHeader,
      data: this.items
    });
  }
}

export default ReportBase;
