<template>
  <div>
    <v-data-table
      :loading="queryLoading"
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-pagination
      multi-sort
    />
  </div>
</template>

<script>
import Component from "vue-class-component";
import ReportBase from "../ReportBase";
import { OVERVIEW } from "../queries.graphql";
import {
  childRefEnum,
  parentHeader
} from "@/view/content/ServiceManagementReport/constants";
import { CATEGORY_FILTERS_ENUM } from "@/core/constants";

@Component
class OverviewSecurityIncident extends ReportBase {
  queryLoading = false;
  items = [];
  headers = [
    { text: "Severity", value: "level" },
    { text: "Amount", value: "amount" },
    { text: "Average Response Time", value: "averageResponseTime" },
    { text: "Response Time Ok", value: "responseTimeOk" },
    { text: "Average Resolve Time", value: "averageResolveTime" },
    { text: "Resolve Time Ok", value: "resolveTimeOk" }
  ];

  async parseAndRetrieveData({ openedAtFilter, accountNumberFilter }) {
    const settings = {
      query: {
        variables: {
          filters: {
            openedAt: openedAtFilter,
            accountNumber: accountNumberFilter,
            category: { value: [CATEGORY_FILTERS_ENUM.SECURITY_INCIDENT] }
          }
        },
        query: OVERVIEW,
        path: "reports.serviceManagementReport.overviews.result"
      },
      level: {},
      parseOptions: []
    };
    this.queryLoading = true;

    let response = await this.queryData(settings.query);

    this.items = this.parseOverview({ data: response });

    const sortList = ["CRITICAL", "HIGH", "MODERATE", "LOW"];
    const sortedList = [];
    sortList.forEach(key => {
      this.items.forEach(item => {
        if (item.level === key) {
          sortedList.push(item);
        }
      });
    });
    this.items = sortedList;

    this.dataLoaded(
      childRefEnum.OVERVIEW_SECURITY_INCIDENTS,
      parentHeader.CREATED_SECURITY_INCIDENTS
    );
    this.queryLoading = false;
  }
}
export default OverviewSecurityIncident;
</script>
