import ActualRating from "@/view/content/ServiceManagementReport/Rating/Actual";
import AdministratorOverview from "@/view/content/ServiceManagementReport/Overview/Administrators";
import AvailabilityManagement from "@/view/content/ServiceManagementReport/Text/AvailabilityManagement";
import ClosedChanges from "@/view/content/ServiceManagementReport/Closed/Changes";
import ClosedProblems from "@/view/content/ServiceManagementReport/Closed/Problems";
import ClosedIncidents from "@/view/content/ServiceManagementReport/Closed/Incident";
import ClosedSecurityIncidents from "@/view/content/ServiceManagementReport/Closed/SecurityIncident";
import ClosedServiceRequests from "@/view/content/ServiceManagementReport/Closed/ServiceRequest";
import ConfigurationItemOverview from "@/view/content/ServiceManagementReport/Overview/ConfigurationItems";
import CreatedChanges from "@/view/content/ServiceManagementReport/Created/Changes";
import CreatedProblems from "@/view/content/ServiceManagementReport/Created/Problems";
import CreatedIncidents from "@/view/content/ServiceManagementReport/Created/Incident";
import CreatedSecurityIncidents from "@/view/content/ServiceManagementReport/Created/SecurityIncident";
import CreatedServiceRequests from "@/view/content/ServiceManagementReport/Created/ServiceRequest";
import HistoricRating from "@/view/content/ServiceManagementReport/Rating/Historic";
import LowestRating from "@/view/content/ServiceManagementReport/Rating/Lowest";
import ManagementSummary from "@/view/content/ServiceManagementReport/Text/ManagementSummary";
import OpenChanges from "@/view/content/ServiceManagementReport/Open/Changes";
import OpenProblems from "@/view/content/ServiceManagementReport/Open/Problems";
import OpenIncidents from "@/view/content/ServiceManagementReport/Open/Incident";
import OpenSecurityIncidents from "@/view/content/ServiceManagementReport/Open/SecurityIncident";
import OpenServiceRequests from "@/view/content/ServiceManagementReport/Open/ServiceRequest";
import OverviewChange from "@/view/content/ServiceManagementReport/Overview/Change";
import OverviewIncident from "@/view/content/ServiceManagementReport/Overview/Incident";
import OverviewSecurityIncident from "@/view/content/ServiceManagementReport/Overview/SecurityIncident";
import SSLCertificates from "@/view/content/ServiceManagementReport/Text/SSLCertificates";

const viewTypeEnum = Object.freeze({
  ADMIN: "admin",
  CI: "ci",
  OVERVIEW: "overview",
  OVERVIEW_TABLE: "overview_table",
  RATING: "rating",
  TEXT: "text",
  CUSTOMER_SATISFACTION: "customer_satisfaction"
});

export const childRefEnum = Object.freeze({
  ACTUAL_RATING: "actualRating",
  ADMINISTRATORS: "administrator",
  AVAILABILITY_MANAGEMENT: "availabilityManagement",
  CLOSED_CHANGES: "closedChanges",
  CLOSED_INCIDENTS: "closedIncidents",
  CLOSED_PROBLEMS: "closedProblems",
  CLOSED_SECURITY_INCIDENTS: "closedSecurityIncidents",
  CLOSED_SERVICE_REQUESTS: "closedServiceRequests",
  CONFIGURATION_ITEMS: "configurationItemTable",
  CREATED_CHANGES: "createdChanges",
  CREATED_INCIDENTS: "createdIncidents",
  CREATED_PROBLEMS: "createdProblems",
  CREATED_SECURITY_INCIDENTS: "createdSecurityIncidents",
  CREATED_SERVICE_REQUESTS: "createdServiceRequests",
  HISTORIC_RATING: "historicRating",
  LOWEST_RATING: "lowestRatingTable",
  MANAGEMENT_SUMMARY: "managementSummary",
  OPEN_CHANGES: "openChanges",
  OPEN_INCIDENTS: "openIncidents",
  OPEN_PROBLEMS: "openProblems",
  OPEN_SECURITY_INCIDENTS: "openSecurityIncidents",
  OPEN_SERVICE_REQUESTS: "openServiceRequests",
  OVERVIEW_CHANGES: "overviewChanges",
  OVERVIEW_INCIDENTS: "overviewIncidents",
  OVERVIEW_SECURITY_INCIDENTS: "overviewSecurityIncidents",
  SSL_CERTIFICATE: "sslCertificate"
});

export const parentHeader = Object.freeze({
  AVAILABILITY_MANAGEMENT: "Availability Management",
  CLOSED_CHANGES: "Closed Changes",
  CLOSED_INCIDENTS: "Closed Incidents",
  CLOSED_PROBLEMS: "Closed Problems",
  CLOSED_SECURITY_INCIDENTS: "Closed Security Incidents",
  CLOSED_SERVICE_REQUESTS: "Closed Service Requests",
  CONFIGURATION_ITEMS: "Configuration Items",
  CREATED_CHANGES: "Created Changes",
  CREATED_INCIDENTS: "Created Incidents",
  CREATED_PROBLEMS: "Created Problems",
  CREATED_SECURITY_INCIDENTS: "Created Security Incidents",
  CREATED_SERVICE_REQUESTS: "Created Service Requests",
  CUSTOMER_ADMINISTRATORS: "Customer Administrators",
  CUSTOMER_SATISFACTION: "Customer Satisfaction",
  LOWEST_RATED_CALLS: "Lowest Rated Calls",
  MANAGEMENT_SUMMARY: "Management Summary",
  OPEN_CHANGES: "Open Changes",
  OPEN_INCIDENTS: "Open Incidents",
  OPEN_PROBLEMS: "Open Problems",
  OPEN_SECURITY_INCIDENTS: "Open Security Incidents",
  OPEN_SERVICE_REQUESTS: "Open Service Requests",
  OVERVIEW_CHANGES: "Changes Management Overview",
  OVERVIEW_INCIDENTS: "Incident Management Overview",
  OVERVIEW_SECURITY_INCIDENTS: "Security Incident Management Overview",
  SSL_CERTIFICATES: "SSL Certificates"
});

export const sections = [
  {
    header: parentHeader.MANAGEMENT_SUMMARY,
    selected: true,
    children: [
      {
        component: ManagementSummary,
        ref: childRefEnum.MANAGEMENT_SUMMARY,
        type: viewTypeEnum.TEXT
      }
    ]
  },
  {
    header: parentHeader.CUSTOMER_SATISFACTION,
    selected: true,
    children: [
      {
        component: ActualRating,
        ref: childRefEnum.ACTUAL_RATING,
        type: viewTypeEnum.CUSTOMER_SATISFACTION,
        subTitle: "Actual Rating"
      },
      {
        component: HistoricRating,
        ref: childRefEnum.HISTORIC_RATING,
        type: viewTypeEnum.CUSTOMER_SATISFACTION,
        subTitle: "Historic Rating"
      }
    ]
  },
  {
    header: parentHeader.LOWEST_RATED_CALLS,
    selected: true,
    children: [
      {
        component: LowestRating,
        ref: childRefEnum.LOWEST_RATING,
        type: viewTypeEnum.RATING
      }
    ]
  },
  {
    header: parentHeader.AVAILABILITY_MANAGEMENT,
    selected: true,
    children: [
      {
        component: AvailabilityManagement,
        ref: childRefEnum.AVAILABILITY_MANAGEMENT,
        type: viewTypeEnum.TEXT
      }
    ]
  },
  {
    header: parentHeader.SSL_CERTIFICATES,
    selected: true,
    children: [
      {
        component: SSLCertificates,
        ref: childRefEnum.SSL_CERTIFICATE,
        type: viewTypeEnum.TEXT
      }
    ]
  },
  {
    header: parentHeader.OVERVIEW_INCIDENTS,
    selected: false,
    children: [
      {
        component: OverviewIncident,
        ref: childRefEnum.OVERVIEW_INCIDENTS,
        type: viewTypeEnum.OVERVIEW_TABLE
      }
    ]
  },
  {
    header: parentHeader.CREATED_INCIDENTS,
    selected: false,
    children: [
      {
        component: CreatedIncidents,
        ref: childRefEnum.CREATED_INCIDENTS,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.OPEN_INCIDENTS,
    selected: false,
    children: [
      {
        component: OpenIncidents,
        ref: childRefEnum.OPEN_INCIDENTS,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.CLOSED_INCIDENTS,
    selected: false,
    children: [
      {
        component: ClosedIncidents,
        ref: childRefEnum.CLOSED_INCIDENTS,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.OVERVIEW_SECURITY_INCIDENTS,
    selected: false,
    children: [
      {
        component: OverviewSecurityIncident,
        ref: childRefEnum.OVERVIEW_SECURITY_INCIDENTS,
        type: viewTypeEnum.OVERVIEW_TABLE
      }
    ]
  },
  {
    header: parentHeader.CREATED_SECURITY_INCIDENTS,
    selected: false,
    children: [
      {
        component: CreatedSecurityIncidents,
        ref: childRefEnum.CREATED_SECURITY_INCIDENTS,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.OPEN_SECURITY_INCIDENTS,
    selected: false,
    children: [
      {
        component: OpenSecurityIncidents,
        ref: childRefEnum.OPEN_SECURITY_INCIDENTS,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.CLOSED_SECURITY_INCIDENTS,
    selected: false,
    children: [
      {
        component: ClosedSecurityIncidents,
        ref: childRefEnum.CLOSED_SECURITY_INCIDENTS,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.OVERVIEW_CHANGES,
    selected: false,
    children: [
      {
        component: OverviewChange,
        ref: childRefEnum.OVERVIEW_CHANGES,
        type: viewTypeEnum.OVERVIEW_TABLE
      }
    ]
  },
  {
    header: parentHeader.CREATED_CHANGES,
    selected: false,
    children: [
      {
        component: CreatedChanges,
        ref: childRefEnum.CREATED_CHANGES,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.OPEN_CHANGES,
    selected: false,
    children: [
      {
        component: OpenChanges,
        ref: childRefEnum.OPEN_CHANGES,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.CLOSED_CHANGES,
    selected: false,
    children: [
      {
        component: ClosedChanges,
        ref: childRefEnum.CLOSED_CHANGES,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.CREATED_PROBLEMS,
    selected: false,
    children: [
      {
        component: CreatedProblems,
        ref: childRefEnum.CREATED_PROBLEMS,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.OPEN_PROBLEMS,
    selected: false,
    children: [
      {
        component: OpenProblems,
        ref: childRefEnum.OPEN_PROBLEMS,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.CLOSED_PROBLEMS,
    selected: false,
    children: [
      {
        component: ClosedProblems,
        ref: childRefEnum.CLOSED_PROBLEMS,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.CREATED_SERVICE_REQUESTS,
    selected: false,
    children: [
      {
        component: CreatedServiceRequests,
        ref: childRefEnum.CREATED_SERVICE_REQUESTS,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.OPEN_SERVICE_REQUESTS,
    selected: false,
    children: [
      {
        component: OpenServiceRequests,
        ref: childRefEnum.OPEN_SERVICE_REQUESTS,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.CLOSED_SERVICE_REQUESTS,
    selected: false,
    children: [
      {
        component: ClosedServiceRequests,
        ref: childRefEnum.CLOSED_SERVICE_REQUESTS,
        type: viewTypeEnum.OVERVIEW
      }
    ]
  },
  {
    header: parentHeader.CUSTOMER_ADMINISTRATORS,
    selected: false,
    children: [
      {
        component: AdministratorOverview,
        ref: childRefEnum.ADMINISTRATORS,
        type: viewTypeEnum.ADMIN
      }
    ]
  },
  {
    header: parentHeader.CONFIGURATION_ITEMS,
    selected: true,
    children: [
      {
        component: ConfigurationItemOverview,
        ref: childRefEnum.CONFIGURATION_ITEMS,
        type: viewTypeEnum.CI
      }
    ]
  }
];
