import gql from "graphql-tag";

const GET_ACCOUNT_IN_ALL_PLATFORMS = gql`
  query($account_number: String!) {
    account(account_number: $account_number) {
      accountid
      accountnumber
      address1_line1
      address1_postalcode
      address1_city
      address1_country
      createdon
      modifiedon
      name
      opsteam_name
      sen_accountnumberold
      sen_strategiccustomer
      telephone1
      servicenow_account {
        city
        country
        phone
        name
        number
        street
        sys_created_on
        sys_id
        sys_updated_on
        u_contract_value_indicator
        u_customer_id
        u_customer_team
        u_old_account_number
        zip
      }
    }
  }
`;

const GET_ACCOUNT_IN_ALL_PLATFORMS_OLD = gql`
  query($account_number_old: String) {
    accounts(account_number_old: $account_number_old) {
      accountid
      accountnumber
      address1_line1
      address1_postalcode
      address1_city
      address1_country
      createdon
      modifiedon
      name
      opsteam_name
      sen_accountnumberold
      sen_strategiccustomer
      telephone1
      servicenow_account {
        city
        country
        phone
        name
        number
        street
        sys_created_on
        sys_id
        sys_updated_on
        u_contract_value_indicator
        u_customer_id
        u_customer_team
        u_old_account_number
        zip
      }
    }
  }
`;

const GET_ACCOUNTS = gql`
  query($filters: AccountFilterInput) {
    accounts(filters: $filters) {
      name
      accountnumber
    }
  }
`;

const LIST_ACCOUNTS_WITH_FILTERS = gql`
  query($filters: AccountFilterInput) {
    accounts(filters: $filters) {
      accountid
      accountnumber
      name
      sen_accountnumberold
    }
  }
`;

const GET_ACCOUNT_WITH_FEATUREPLAN = gql`
  query($account_number: String!) {
    account(account_number: $account_number) {
      accountid
      features
    }
  }
`;

export {
  GET_ACCOUNT_IN_ALL_PLATFORMS,
  GET_ACCOUNT_IN_ALL_PLATFORMS_OLD,
  GET_ACCOUNTS,
  GET_ACCOUNT_WITH_FEATUREPLAN,
  LIST_ACCOUNTS_WITH_FILTERS
};
