import gql from "graphql-tag";

export const DETAILS = gql`
  query Details($filters: ServiceManagementReportDetailFilterInput!) {
    reports {
      serviceManagementReport {
        details(filters: $filters) {
          totalCount
          metadata {
            conversationId
          }
          result {
            caseNumber
            number
            priority
            rating
            resolveTime
            responseTime
            severity
            shortDescription
            subCategory
            openedAt
            type
          }
        }
      }
    }
  }
`;

export const OVERVIEW = gql`
  query Overview($filters: ServiceManagementReportOverviewFilterInput!) {
    reports {
      serviceManagementReport {
        overviews(filters: $filters) {
          result {
            amount
            averageResolveTime
            averageResponseTime
            level
            resolveTimeOk
            responseTimeOk
            slaResolveTime
            slaResponseTime
            averageRating
          }
        }
      }
    }
  }
`;

export const GET_RATING = gql`
  query Ratings($filters: ServiceManagementReportRatingFilterInput!) {
    reports {
      serviceManagementReport {
        ratings(filters: $filters) {
          totalCount
          result {
            actualValue
            shortDescription
            user
            number
            level
          }
          averageResult
        }
      }
    }
  }
`;

export const CUSTOMER_SYSTEM_ADMINISTRATOR_OVERVIEW = gql`
  query CustomerSystemAdministrator(
    $filters: ServiceManagementReportCustomerSystemAdministratorFilterInput
  ) {
    reports {
      serviceManagementReport {
        customerSystemAdministrators(filters: $filters) {
          result {
            accountName
            accountNumber
            users {
              email
              name
            }
          }
        }
      }
    }
  }
`;

export const CI_OVERVIEW = gql`
  query ConfigurationItems(
    $account_number: String!
    $instance: DataSourceInstance
    $pagination: PaginationInput
    $query_options: QueryOptions
  ) {
    account(account_number: $account_number) {
      name
      configuration_items(
        instance: $instance
        pagination: $pagination
        query_options: $query_options
      ) {
        total_count
        result {
          name
          sys_class_name
          os
          fqdn
        }
      }
    }
  }
`;

export const INCIDENT_OVERVIEW = gql`
  query IncidentOverview($filters: ServiceManagementReportOverviewFilterInput) {
    reports {
      service_management_report {
        overview {
          incidents(filters: $filters) {
            amount
            averageResolveTime
            averageResponseTime
            level
            resolveTimeOk
            responseTimeOk
            slaResolveTime
            slaResponseTime
            averageRating
          }
        }
      }
    }
  }
`;

export const INCIDENT_DETAIL = gql`
  query IncidentDetail($filters: ServiceManagementReportDetailFilterInput) {
    reports {
      service_management_report {
        details {
          incidents(filters: $filters) {
            total_count
            result {
              number
              caseNumber
              shortDescription
              priority
              severity
            }
          }
        }
      }
    }
  }
`;

export const SERVICE_REQUEST_DETAIL = gql`
  query ServiceRequestDetail(
    $filters: ServiceManagementReportDetailFilterInput
  ) {
    reports {
      service_management_report {
        details {
          service_requests(filters: $filters) {
            total_count
            result {
              number
              caseNumber
              priority
              shortDescription
              severity
            }
          }
        }
      }
    }
  }
`;
