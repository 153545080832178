<template>
  <div>
    Closed problems for the selected reporting period
    <v-data-table
      :loading="queryLoading"
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-pagination
      multi-sort
    />
  </div>
</template>

<script>
import Component from "vue-class-component";
import ReportBase from "../ReportBase";
import { DETAILS } from "../queries.graphql";
import {
  childRefEnum,
  parentHeader
} from "@/view/content/ServiceManagementReport/constants";
import { CATEGORY_FILTERS_ENUM } from "@/core/constants";

@Component
class ClosedProblems extends ReportBase {
  queryLoading = false;
  items = [];
  headers = [
    { text: "Call", value: "caseNumber" },
    { text: "Subject", value: "shortDescription" },
    { text: "Priority", value: "priority" }
  ];

  async parseAndRetrieveData({ openedAtFilter, accountNumberFilter }) {
    const settings = {
      query: {
        variables: {
          filters: {
            openedAt: openedAtFilter,
            accountNumber: accountNumberFilter,
            state: this.closedProblemFilter,
            category: { value: [CATEGORY_FILTERS_ENUM.PROBLEM] }
          }
        },
        query: DETAILS,
        path: "reports.serviceManagementReport.details.result"
      }
    };
    this.queryLoading = true;

    let items = await this.queryData(settings.query);
    this.items = this.setLevel({ data: items, levelName: "priority" });

    this.dataLoaded(childRefEnum.CLOSED_PROBLEMS, parentHeader.CLOSED_PROBLEMS);
    this.queryLoading = false;
  }
}
export default ClosedProblems;
</script>
