<template>
  <div>
    Closed security incidents for the selected reporting period
    <v-data-table
      :loading="queryLoading"
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-pagination
      multi-sort
    />
  </div>
</template>

<script>
import Component from "vue-class-component";
import ReportBase from "../ReportBase";
import { CATEGORY_FILTERS_ENUM } from "@/core/constants";
import {
  childRefEnum,
  parentHeader
} from "@/view/content/ServiceManagementReport/constants";
import { DETAILS } from "@/view/content/ServiceManagementReport/queries.graphql";

@Component
class ClosedSecurityIncidents extends ReportBase {
  queryLoading = false;
  items = [];
  headers = [
    { text: "Call", value: "caseNumber" },
    { text: "Subject", value: "shortDescription" },
    { text: "Severity", value: "severity" }
  ];

  async parseAndRetrieveData({ openedAtFilter, accountNumberFilter }) {
    const settings = {
      query: {
        variables: {
          filters: {
            openedAt: openedAtFilter,
            accountNumber: accountNumberFilter,
            state: this.closedIncidentFilter,
            category: { value: [CATEGORY_FILTERS_ENUM.SECURITY_INCIDENT] }
          }
        },
        query: DETAILS,
        path: "reports.serviceManagementReport.details.result"
      }
    };
    this.queryLoading = true;

    let items = await this.queryData(settings.query);
    this.items = this.setLevel({ data: items, levelName: "severity" });

    this.dataLoaded(
      childRefEnum.CLOSED_SECURITY_INCIDENTS,
      parentHeader.CLOSED_SECURITY_INCIDENTS
    );
    this.queryLoading = false;
  }
}
export default ClosedSecurityIncidents;
</script>
