<template>
  <div>
    <v-data-table
      :loading="queryLoading"
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-pagination
      multi-sort
    />
  </div>
</template>

<script>
import Component from "vue-class-component";
import ReportBase from "../ReportBase";
import { GET_RATING } from "../queries.graphql";
import { OPERATOR_ENUM } from "@/core/constants";
import {
  childRefEnum,
  parentHeader
} from "@/view/content/ServiceManagementReport/constants";

@Component
class LowestRating extends ReportBase {
  queryLoading = false;
  items = [];
  headers = [
    { text: "Call", value: "number" },
    { text: "Subject", value: "shortDescription" },
    { text: "Rated By", value: "user" },
    { text: "Rating", value: "actualValue" }
  ];

  async parseAndRetrieveData({ accountNumberFilter, openedAtFilter }) {
    const settings = {
      query: {
        variables: {
          filters: {
            openedAt: openedAtFilter,
            accountNumber: accountNumberFilter,
            actualValue: {
              value: [3],
              operator: OPERATOR_ENUM.LESS_THAN_EQUALS
            }
          }
        },
        query: GET_RATING,
        path: "reports.serviceManagementReport.ratings.result"
      },
      level: {},
      parseOptions: []
    };
    this.queryLoading = true;

    let response = await this.queryData(settings.query);

    this.items = this.parseDetail({
      data: response,
      parseOptions: settings.parseOptions
    });
    this.items.sort((a, b) => a.actualValue - b.actualValue);
    this.items = this.items.slice(0, 7);

    this.dataLoaded(
      childRefEnum.LOWEST_RATING,
      parentHeader.LOWEST_RATED_CALLS
    );
    this.queryLoading = false;
  }
}
export default LowestRating;
</script>
