<template>
  <div>
    Created changes for the selected reporting period
    <v-data-table
      :loading="queryLoading"
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-pagination
      multi-sort
    />
  </div>
</template>
<script>
import Component from "vue-class-component";
import ReportBase from "../ReportBase";
import { DETAILS } from "../queries.graphql";
import {
  childRefEnum,
  parentHeader
} from "@/view/content/ServiceManagementReport/constants";
import {
  CATEGORY_FILTERS_ENUM,
  CHANGE_STATE_FILTER_ENUM,
  OPERATOR_ENUM
} from "@/core/constants";

@Component
class CreatedChanges extends ReportBase {
  queryLoading = false;
  items = [];
  headers = [
    { text: "Call", value: "caseNumber" },
    { text: "Subject", value: "shortDescription" },
    { text: "Type", value: "type" }
  ];

  async parseAndRetrieveData({ openedAtFilter, accountNumberFilter }) {
    const settings = {
      query: {
        variables: {
          filters: {
            openedAt: openedAtFilter,
            accountNumber: accountNumberFilter,
            state: {
              value: Object.values(CHANGE_STATE_FILTER_ENUM),
              operator: OPERATOR_ENUM.IN
            },
            category: { value: [CATEGORY_FILTERS_ENUM.CHANGE_REQUEST] }
          }
        },
        query: DETAILS,
        path: "reports.serviceManagementReport.details.result"
      }
    };
    this.queryLoading = true;

    let items = await this.queryData(settings.query);
    this.items = this.setLevel({ data: items, levelName: "type" });

    this.dataLoaded(childRefEnum.CREATED_CHANGES, parentHeader.CREATED_CHANGES);
    this.queryLoading = false;
  }
}
export default CreatedChanges;
</script>
