<template>
  <div>
    <v-progress-linear
      :active="queryLoading"
      :background-opacity="0.3"
      :height="2"
      :indeterminate="true"
      :stream="true"
      :value="100"
      color="primary"
    />
    <v-card-text>
      {{ items.averageRating }} <br />
      {{ items.totalCount }} calls
    </v-card-text>
  </div>
</template>

<script>
import Component from "vue-class-component";
import ReportBase from "../ReportBase";
import { GET_RATING } from "../queries.graphql";
import {
  childRefEnum,
  parentHeader
} from "@/view/content/ServiceManagementReport/constants";

@Component
class ActualRating extends ReportBase {
  queryLoading = false;
  items = {};

  async parseAndRetrieveData({ accountNumberFilter, openedAtFilter }) {
    const settings = {
      query: {
        variables: {
          filters: {
            accountNumber: accountNumberFilter,
            openedAt: openedAtFilter
          }
        },
        query: GET_RATING,
        path: "reports.serviceManagementReport.ratings"
      },
      parseOptions: []
    };
    this.queryLoading = true;

    let response = await this.queryData(settings.query);

    this.items = this.parseRating({ data: response });

    this.dataLoaded(
      childRefEnum.ACTUAL_RATING,
      parentHeader.CUSTOMER_SATISFACTION
    );
    this.queryLoading = false;
  }
}
export default ActualRating;
</script>
