<template>
  <div>
    <v-data-table
      :loading="queryLoading"
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-pagination
      multi-sort
    />
  </div>
</template>

<script>
import Component from "vue-class-component";
import ReportBase from "../ReportBase";
import { INSTANCE_ENUM } from "@/core/constants";
import { CI_OVERVIEW } from "../queries.graphql";
import {
  childRefEnum,
  parentHeader
} from "@/view/content/ServiceManagementReport/constants";

@Component
class ConfigurationItemOverview extends ReportBase {
  queryLoading = false;
  items = [];
  headers = [
    { text: "Name", value: "name" },
    { text: "FQDN", value: "fqdn" },
    { text: "Type", value: "sys_class_name" },
    { text: "OS", value: "os" }
  ];

  async parseAndRetrieveData({ accountNumber }) {
    const settings = {
      query: {
        variables: {
          account_number: accountNumber,
          instance: INSTANCE_ENUM.SNOW_GROUP,
          query_options: { display_value: true },
          pagination: { limit: -1 }
        },
        query: CI_OVERVIEW,
        path: "account.configuration_items.result",
        client: "salaClient"
      },
      level: {},
      parseOptions: []
    };
    this.queryLoading = true;

    let response = await this.queryData(settings.query);

    this.items = this.parseDetail({
      data: response,
      parseOptions: settings.parseOptions
    });

    this.dataLoaded(
      childRefEnum.CONFIGURATION_ITEMS,
      parentHeader.CONFIGURATION_ITEMS
    );
    this.queryLoading = false;
  }
}

export default ConfigurationItemOverview;
</script>
