<template>
  <div>
    Created service requests for the selected reporting period
    <v-data-table
      :loading="queryLoading"
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-pagination
      multi-sort
    />
  </div>
</template>
<script>
import Component from "vue-class-component";
import ReportBase from "../ReportBase";
import {
  CATEGORY_FILTERS_ENUM,
  INCIDENT_STATE_FILTER_ENUM,
  OPERATOR_ENUM
} from "@/core/constants";
import { DETAILS } from "../queries.graphql";
import {
  childRefEnum,
  parentHeader
} from "@/view/content/ServiceManagementReport/constants";

@Component
class CreatedServiceRequests extends ReportBase {
  queryLoading = false;
  items = [];
  headers = [
    { text: "Call", value: "caseNumber" },
    { text: "Subject", value: "shortDescription" },
    { text: "Priority", value: "priority" }
  ];

  async parseAndRetrieveData({ openedAtFilter, accountNumberFilter }) {
    const settings = {
      query: {
        variables: {
          filters: {
            openedAt: openedAtFilter,
            accountNumber: accountNumberFilter,
            category: { value: CATEGORY_FILTERS_ENUM.SERVICE_REQUEST },
            state: {
              value: Object.values(INCIDENT_STATE_FILTER_ENUM),
              operator: OPERATOR_ENUM.IN
            }
          }
        },
        query: DETAILS,
        path: "reports.serviceManagementReport.details.result"
      }
    };
    this.queryLoading = true;

    let items = await this.queryData(settings.query);
    this.items = this.setLevel({ data: items, levelName: "priority" });

    this.dataLoaded(
      childRefEnum.CREATED_SERVICE_REQUESTS,
      parentHeader.CREATED_SERVICE_REQUESTS
    );
    this.queryLoading = false;
  }
}
export default CreatedServiceRequests;
</script>
