<template>
  <div>
    <v-data-table
      :loading="queryLoading"
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-pagination
      multi-sort
    />
  </div>
</template>

<script>
import Component from "vue-class-component";
import ReportBase from "../ReportBase";
import { CUSTOMER_SYSTEM_ADMINISTRATOR_OVERVIEW } from "../queries.graphql";
import {
  childRefEnum,
  parentHeader
} from "@/view/content/ServiceManagementReport/constants";

@Component
class AdministratorOverview extends ReportBase {
  queryLoading = false;
  items = [];
  headers = [
    { text: "Name", value: "name" },
    { text: "Email", value: "email" }
  ];

  async parseAndRetrieveData({ accountNumberFilter }) {
    const settings = {
      query: {
        variables: {
          filters: {
            accountNumber: accountNumberFilter
          }
        },
        query: CUSTOMER_SYSTEM_ADMINISTRATOR_OVERVIEW,
        path:
          "reports.serviceManagementReport.customerSystemAdministrators.result.users"
      },
      level: {},
      parseOptions: []
    };
    this.queryLoading = true;

    let response = await this.queryData(settings.query);

    this.items = this.parseDetail({
      data: response,
      parseOptions: settings.parseOptions
    });

    this.dataLoaded(
      childRefEnum.ADMINISTRATORS,
      parentHeader.CUSTOMER_ADMINISTRATORS
    );
    this.queryLoading = false;
  }
}
export default AdministratorOverview;
</script>
