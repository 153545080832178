<template>
  <div>{{ items.text }}</div>
</template>

<script>
import Component from "vue-class-component";
import ReportBase from "../ReportBase";
import {
  childRefEnum,
  parentHeader
} from "@/view/content/ServiceManagementReport/constants";

@Component
class ManagementSummary extends ReportBase {
  queryLoading = false;
  items = {};

  async parseAndRetrieveData() {
    this.items = { text: "Place your text here" };
    this.dataLoaded(
      childRefEnum.MANAGEMENT_SUMMARY,
      parentHeader.MANAGEMENT_SUMMARY
    );
  }
}
export default ManagementSummary;
</script>
