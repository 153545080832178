<template>
  <div>
    All open changes, these are not specific for the selected period
    <v-data-table
      :loading="queryLoading"
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-pagination
      multi-sort
    />
  </div>
</template>
<script>
import Component from "vue-class-component";
import ReportBase from "../ReportBase";
import { DETAILS } from "../queries.graphql";
import {
  childRefEnum,
  parentHeader
} from "@/view/content/ServiceManagementReport/constants";
import { CATEGORY_FILTERS_ENUM } from "@/core/constants";

@Component
class OpenChanges extends ReportBase {
  queryLoading = false;
  items = [];
  headers = [
    { text: "Call", value: "caseNumber" },
    { text: "Subject", value: "shortDescription" },
    { text: "Type", value: "type" }
  ];

  async parseAndRetrieveData({ accountNumberFilter }) {
    const settings = {
      query: {
        variables: {
          filters: {
            accountNumber: accountNumberFilter,
            state: this.openChangeFilter,
            category: { value: [CATEGORY_FILTERS_ENUM.CHANGE_REQUEST] }
          }
        },
        query: DETAILS,
        path: "reports.serviceManagementReport.details.result"
      }
    };
    this.queryLoading = true;

    let items = await this.queryData(settings.query);
    this.items = this.setLevel({ data: items, levelName: "type" });

    this.dataLoaded(childRefEnum.OPEN_CHANGES, parentHeader.OPEN_CHANGES);
    this.queryLoading = false;
  }
}
export default OpenChanges;
</script>
