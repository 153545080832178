<template>
  <div>{{ items.text }}</div>
</template>

<script>
import Component from "vue-class-component";
import ReportBase from "../ReportBase";
import {
  childRefEnum,
  parentHeader
} from "@/view/content/ServiceManagementReport/constants";

@Component
class AvailabilityManagement extends ReportBase {
  queryLoading = false;
  items = {};

  async parseAndRetrieveData() {
    this.items = { text: "Place your text here" };
    this.dataLoaded(
      childRefEnum.AVAILABILITY_MANAGEMENT,
      parentHeader.AVAILABILITY_MANAGEMENT
    );
  }
}
export default AvailabilityManagement;
</script>
